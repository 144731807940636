import { api, invoices, notifications } from '../api/api';

const billingService = {
  createBilling: async (billingData) => {
    try {
      const response = await invoices.post('/create', billingData);

      return response.data;
    } catch (error) {
      throw Error('Erro ao criar cobrança');
    }
  },
  createBillings: async (billingData) => {
    try {
      const response = await invoices.post('/create_many', billingData);

      return response.data;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data));
    }
  },
  getBillings: async (filters) => {
    try {
      const response = await invoices.get('/installments', { params: filters });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar cobranças');
    }
  },
  getBilling: async (id) => {
    try {
      const response = await invoices.get(`/installment/${id}`);

      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da cobrança');
    }
  },
  getInvoice: async (id) => {
    try {
      const response = await api.get(`/invoice/${id}`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da cobrança');
    }
  },
  getInvoiceInstallments: async (id) => {
    try {
      const response = await api.get(`/invoice/${id}/installments`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar pacelamentos');
    }
  },
  getBillingNotifications: async (id) => {
    try {
      const response = await notifications.get(`/sents/${id}`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar notificações da cobrança');
    }
  },
  deleteBilling: async (id) => {
    try {
      const response = await invoices.delete(`/ins?installment_ids=["${id}"]`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao deletar cobrança');
    }
  },
  deleteInvoice: async (id) => {
    try {
      const response = await invoices.delete('', {
        params: {
          invoice_ids: `["${id}"]`
        }
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao deletar cobrança');
    }
  },
  deleteBillings: async (billingIds) => {
    const ids = billingIds.map((id) => `"${id}"`).join(',');
    try {
      const response = await invoices.delete(`/ins?installment_ids=[${ids}]`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao deletar cobranças');
    }
  },
  changeBillingStatus: async (
    id,
    status,
    { paidWhere, paidWhen, notifyCustomer, renewBilling }
  ) => {
    // const notify = notifyCustomer == 'yes' ? true : false;
    try {
      const response = await invoices.post(`/installment/payment`, {
        installment_id: id,
        confirm_payment: status,
        paid_where: paidWhere,
        paid_when: paidWhen,
        notify_customer: notifyCustomer == 'yes' ? true : false,
        renew_billing: renewBilling == 'yes' ? true : false
      });

      return response.data;
    } catch (error) {
      throw Error('Erro ao atualizar status da cobrança');
    }
  },
  changeMultipleBillingsStatuses: async (
    installments,
    status,
    { paidWhere, paidWhen, notifyCustomer }
  ) => {
    // const notify = notifyCustomer == 'yes' ? true : false;
    try {
      const response = await invoices.post(`/installment/payments`, {
        installments,
        confirm_payment: status,
        paid_where: paidWhere,
        paid_when: paidWhen,
        notify_customer: notifyCustomer == 'yes' ? true : false
      });

      return response.data;
    } catch (error) {
      throw Error(JSON.stringify(error.response.data));
    }
  },
  getExcelData: async (filters) => {
    try {
      const response = await api.get('/installments/export', { params: filters });
      return response.data;
    } catch (error) {
      throw Error('Erro ao buscar dados da cobrança');
    }
  }
};

export default billingService;
