import React, { useEffect } from 'react';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import { useParams } from 'react-router';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import { formatDateTime } from '../../../utils/dateHelpers';
import { notifications as notificationsFetcher } from '../../../api/api';

function NotificationsTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Data e Hora',
      accessor: 'date',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{formatDateTime(new Date(row.original.time))}</div>
      )
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original.description}</div>
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

export default function NotificationsSection() {
  const getBillingNotifications = useStore((state) => state.billings.getBillingNotifications);
  const notifications = useStore((state) => state.billings.billing?.notifications);
  const isBillingNotificationsLoading = useStore(
    (state) => state.billings.isBillingNotificationsLoading
  );
  const params = useParams();

  useEffect(() => {
    getBillingNotifications(params.id);
  }, []);

  return (
    <DetailSection title="Notificações enviadas">
      <div className="px-5">
        <NotificationsTable
          data={notifications.data ?? []}
          loading={isBillingNotificationsLoading}
        />
      </div>
    </DetailSection>
  );
}

export function NotSentNotificationsSection() {
  const [notifications, setNotifications] = React.useState([]);
  const params = useParams();
  useEffect(() => {
    (async () => {
      const response = await notificationsFetcher.get('/failures', {
        params: {
          installment_id: params.id
        }
      });

      setNotifications(
        response.data.map((item) => ({
          ...item,
          description: item.error,
          time: item.timestamp
        }))
      );
    })();
  }, [params.id]);

  return (
    <DetailSection title="Notificações não enviadas">
      <div className="px-5">
        <NotificationsTable data={notifications} />
      </div>
    </DetailSection>
  );
}
